import classNames from "classnames";
import * as React from "react";
import Slider from "react-slick";
import CoverSlide from "@components/CoverSlider/CoverSlide";
import { NextArrow, PrevArrow } from "@components/ShopDetail/VisualSlider";
import useIsPc from "@hooks/useIsPc";
import useIsPcByUA from "@hooks/useIsPcByUA";
import { paths } from "@libs/paths";
import styles from "./index.module.css";
import type { Settings } from "react-slick";

const CoverSlider: React.FC = () => {
  const { isPcByUA } = useIsPcByUA.useContainer();
  const isPcByScreenWidth = useIsPc.useContainer();

  const pointSlides = [
    <CoverSlide
      isTargetBlank={false}
      href={"/shops/s-rugby"}
      imgPath="/img/cover_league_one.png"
      alt="ラグビー掲載開始！スポーツバーで観戦しよう！"
    />,
    <CoverSlide
      isTargetBlank={true}
      href={"https://www.saisoncard.co.jp/creditcard/extended/mixi/?mi=1000661"}
      imgPath="/img/cover_saison_202404.png"
      alt="Fanstaからのご入会者様限定 SAMURAI BLUE カード セゾン"
    />,
    <CoverSlide
      isTargetBlank={false}
      href={paths.productionNewsArticleForPointBanner}
      imgPath="/img/cover_point_1_202502.png"
      alt="事前決済での予約でポイント使える"
    />,
    <CoverSlide
      isTargetBlank={false}
      href={paths.productionNewsArticleForPointBanner}
      imgPath="/img/cover_point_2_202502.png"
      alt="さまざまな商品と交換しよう！"
    />,
  ];
  // 例: 訴求するカバー画像がない場合は空にする。
  const coverSlides: Array<React.ReactElement> = [
    ...pointSlides,
    <CoverSlide
      isTargetBlank={false}
      href={paths.sportsLiveViewing}
      imgPath={"/img/web_fansta_top_feature.png"}
      alt="Fanstaを使ってスポーツライブビューイングを楽しもう"
    />,
    <CoverSlide
      isTargetBlank={!!isPcByUA}
      href={paths.appOneLinkCommon}
      imgPath={"/img/cover_app_dl.png"}
      alt="アプリでもかんたんネット予約"
    />,
  ];

  // Fallback to default KeyVisual if no-slides found.
  if (coverSlides.length === 0) {
    return (
      <div className={classNames(styles.keyVisualImage, styles.single)}>
        <CoverSlide
          isTargetBlank={!!isPcByUA}
          href={paths.appOneLinkCommon}
          imgPath={
            isPcByScreenWidth
              ? "/img/pc/cover_app_dl.png"
              : "/img/cover_app_dl.png"
          }
          alt="アプリでもかんたんネット予約"
        />
      </div>
    );
  }

  const SlideSetting: Settings = {
    dots: false,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "28.5%",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: false,
    swipeToSlide: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 812,
        settings: {
          dots: true,
          dotsClass: styles.slideDot,
          centerPadding: "0px",
          swipe: true,
          swipeToSlide: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.keyVisualImage}>
        <Slider {...SlideSetting}>
          {coverSlides.map((slide, i) => (
            <div key={i} className={styles.slideItems}>
              {slide}
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CoverSlider;
